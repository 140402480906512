<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <router-link to="/" tag="a" class="flex mr-auto">
        <img :alt="adminTitle" class="w-6" src="@/assets/images/CISA.svg" />
        <span class="block text-white text-base ml-3">
          {{ adminTitle }}
        </span>
      </router-link>
      <BarChart2Icon
        class="w-8 h-8 text-white transform -rotate-90"
        @click="toggleMobileMenu"
      />
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu" class="border-t border-theme-24 py-5">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li
            v-if="menu == 'devider'"
            :key="menu + menuKey"
            class="menu__devider my-6"
          ></li>
          <li v-else-if="setmap(menu.to)" :key="menu + menuKey">
            <a
              href="javascript:;"
              class="menu"
              :class="{
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown
              }"
              @click="linkTo(menu, router)"
            >
              <div class="menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <div
                  v-if="menu.subNodes"
                  class="menu__sub-icon"
                  :class="{ 'transform rotate-180': menu.activeDropdown }"
                >
                  <ChevronDownIcon />
                </div>
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.subNodes && menu.activeDropdown">
                <li
                  v-for="(subMenu, subMenuKey) in menu.subNodes"
                  :key="subMenuKey"
                >
                  <a
                    href="javascript:;"
                    class="menu"
                    :class="{ 'menu--active': subMenu.active }"
                    @click="linkTo(subMenu, router)"
                  >
                    <div class="menu__icon">
                      <component v-if="subMenu.icon" :is="subMenu.icon" />
                      <ActivityIcon v-else />
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <div
                        v-if="subMenu.subNodes"
                        class="menu__sub-icon"
                        :class="{
                          'transform rotate-180': subMenu.activeDropdown
                        }"
                      >
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="subMenu.subNodes && subMenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu,
                        lastSubMenuKey) in subMenu.subNodes"
                        :key="lastSubMenuKey"
                      >
                        <a
                          href="javascript:;"
                          class="menu"
                          :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu, router)"
                        >
                          <div class="menu__icon">
                            <ZapIcon />
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from "./index";
import context from "@cloudfun/core";

export default defineComponent({
  props: {
    adminTitle: String
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const policy = context.current?.policy;
    const mobileMenu = computed(
      () =>
        policy?.makeMenu(policy.sitemap?.subNodes || [], undefined, false) || []
    );

    const positionList = [
      ["/dashboard", "/announcement", "/user"],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/user"
      ],
      ["/dashboard", "/announcement", "/meeting", "/product", "/user"],
      ["/dashboard", "/announcement", "/meeting", "/user"]
    ];

    const auth = ref<any>({});
    const positions = ref<any>([]);
    const getAuth = () => {
      return context?.current?.model
        .dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            context.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          context.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value);
      }
    );

    onMounted(async () => {
      formattedMenu.value = $h.toRaw(mobileMenu.value);

      auth.value = await getAuth();
      // console.log(auth.value);
      if (auth.value) {
        if (
          auth.value.position === 0 &&
          auth.value.isBuyer === false &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[0];
        } else if (
          auth.value.position === 0 &&
          auth.value.isBuyer === true &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[5];
        } else if (auth.value.isVendor === true) {
          positions.value = positionList[auth.value.position];
        }
      }
      // console.log('positions', positions.value)
    });

    const setmap = (name: string) => {
      // console.log(positions.value, name, positions.value.includes(name))
      return positions.value.includes(name);
    };

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      setmap
    };
  }
});
</script>
