<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <div
        class="breadcrumb sm:flex"
        v-for="(node, index) in $breadcrumb"
        :key="`breadcrumb-${index}`"
      >
        <router-link v-if="$breadcrumb.length !== index + 1" :to="node.to">{{
          node.title
        }}</router-link>
        <span v-else class="breadcrumb--active">{{ node.title }}</span>
        <ChevronRightIcon
          v-if="$breadcrumb.length !== index + 1"
          class="breadcrumb__icon"
        />
      </div>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <!-- <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input input placeholder-theme-13"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href>
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  class="rounded-full"
                  src="/images/no_photo.png"
                />
              </div>
              <div class="ml-3">Tester</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                test@cloudfun.com.tw
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                src="/images/no_photo.png"
              />
            </div>
            <div class="ml-3">名稱</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              分類
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <!-- <div class="intro-x dropdown relative mr-auto sm:mr-6">
      <div
        class="dropdown-toggle notification notification--bullet cursor-pointer"
      >
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="notification-content pt-2 dropdown-box">
        <div
          class="notification-content__box dropdown-box__content box dark:bg-dark-6"
        >
          <div class="notification-content__title">Notifications</div>
          <div class="cursor-pointer relative flex items-center mb-5">
            <div class="w-12 h-12 flex-none image-fit mr-1">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                src="/images/no_photo.png"
              />
              <div
                class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
              ></div>
            </div>
            <div class="w-full ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="font-medium truncate mr-5">
                  Tester
                </a>
                <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                  05:09 AM
                </div>
              </div>
              <div class="w-full truncate text-gray-600 mt-0.5">
                Notifincation Test
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="accountName text-theme-1 text-base font-medium mx-3">
      {{ user?.displayName === "null" ? "" : user?.displayName }}
    </div>
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      >
        <img
          :alt="user?.displayName"
          :src="
            user.avatarImageID && user.avatarImageID.length > 0
              ? `${photoUrl}/${user.avatarImageID}`
              : '/images/no_photo.png'
          "
          onerror="this.onerror=null;this.src='/images/no_photo.png'"
        />
      </div>
      <div class="dropdown-box w-56">
        <div
          class="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white"
        >
          <div class="p-4 border-b border-theme-40 dark:border-dark-3">
            <div class="text-xs text-theme-41 mt-0.5 dark:text-gray-600">
              身份：
            </div>
            <div class="font-medium">
              <span v-if="user">{{ user.identity }}</span>
            </div>
          </div>
          <div class="p-2" v-show="user?.identity">
            <!-- <a
              href
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <SettingsIcon class="w-4 h-4 mr-2" /> 會員基本資料管理
            </a>
            <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
              <LockIcon class="w-4 h-4 mr-2" /> 密碼管理
            </a> -->
            <a
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="logout()"
            >
              <ToggleLeftIcon class="w-4 h-4 mr-2" /> 登出
            </a>
          </div>
          <!-- <div class="p-2 border-t border-theme-40 dark:border-dark-3">
            <a v-if="isGuest" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md" @click="logout()">
              <ToggleRightIcon class="w-4 h-4 mr-2" /> 登入
            </a>
            <a
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <HelpCircleIcon class="w-4 h-4 mr-2" /> 支援服務
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { mapGetters } from "vuex";
import axios from "axios";
import CloudFun from "@cloudfun/core";
export default defineComponent({
  setup() {
    const searchDropdown = ref(false);
    var userData = sessionStorage.getItem("userData")!;
    var user = ref({} as any); // JSON.parse(userData)
    const model = CloudFun.current?.model;
    const photoUrl = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    onMounted(async () => {
      const data = await loadUserData();
      if (data && data.status) {
        user.value = data.payload;
      }

      console.log("loadUserData", user.value);

      // if (user.value.avatarImageID) {
      //   photo.value =
      //     "https://www.socean-cisa.com/api/v1/Image/27440604e70a41d38d4efa9e1690efbe";
      // }

      CloudFun.subscript("reloadAuth", () => {
        console.log("call reloadAuth");
        loadUserData()
          .then(response => {
            if (response.status) {
              console.log("after user reload", response);
              user.value = response.payload;
            } else {
              CloudFun.send("error", {
                subject: "讀取失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: error.message
            });
          });
      });
    });

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      isGuest: !CloudFun.current?.user.Id,
      user,
      loadUserData,
      photoUrl
    };
  },
  computed: {
    ...mapGetters(["user"]),
    homePath() {
      const path = this.$route.path;
      if (path.startsWith("/simple-menu")) return "/simple-menu";
      if (path.startsWith("/top-menu")) return "/top-menu";
      return "/";
    }
  },
  methods: {
    logout() {
      if (!this.user.account) {
        this.$router.push("/login");
        return;
      }
      if (this.user) {
        sessionStorage.clear();
        this.$router.push("/login");
      }
    },
    GetAccount(token: string) {
      return axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/Account`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => response.data?.payload);
    }
  },
  watch: {
    user(current) {
      this.isGuest = !current.Id;
    }
  }
});
</script>
