
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from "./index";
import context from "@cloudfun/core";

export default defineComponent({
  props: {
    adminTitle: String
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const policy = context.current?.policy;
    const mobileMenu = computed(
      () =>
        policy?.makeMenu(policy.sitemap?.subNodes || [], undefined, false) || []
    );

    const positionList = [
      ["/dashboard", "/announcement", "/user"],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/user"
      ],
      ["/dashboard", "/announcement", "/meeting", "/product", "/user"],
      ["/dashboard", "/announcement", "/meeting", "/user"]
    ];

    const auth = ref<any>({});
    const positions = ref<any>([]);
    const getAuth = () => {
      return context?.current?.model
        .dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            context.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          context.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value);
      }
    );

    onMounted(async () => {
      formattedMenu.value = $h.toRaw(mobileMenu.value);

      auth.value = await getAuth();
      // console.log(auth.value);
      if (auth.value) {
        if (
          auth.value.position === 0 &&
          auth.value.isBuyer === false &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[0];
        } else if (
          auth.value.position === 0 &&
          auth.value.isBuyer === true &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[5];
        } else if (auth.value.isVendor === true) {
          positions.value = positionList[auth.value.position];
        }
      }
      // console.log('positions', positions.value)
    });

    const setmap = (name: string) => {
      // console.log(positions.value, name, positions.value.includes(name))
      return positions.value.includes(name);
    };

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      setmap
    };
  }
});
