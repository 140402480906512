
import { defineComponent, onMounted, ref } from "vue";
import { mapGetters } from "vuex";
import axios from "axios";
import CloudFun from "@cloudfun/core";
export default defineComponent({
  setup() {
    const searchDropdown = ref(false);
    var userData = sessionStorage.getItem("userData")!;
    var user = ref({} as any); // JSON.parse(userData)
    const model = CloudFun.current?.model;
    const photoUrl = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    onMounted(async () => {
      const data = await loadUserData();
      if (data && data.status) {
        user.value = data.payload;
      }

      console.log("loadUserData", user.value);

      // if (user.value.avatarImageID) {
      //   photo.value =
      //     "https://www.socean-cisa.com/api/v1/Image/27440604e70a41d38d4efa9e1690efbe";
      // }

      CloudFun.subscript("reloadAuth", () => {
        console.log("call reloadAuth");
        loadUserData()
          .then(response => {
            if (response.status) {
              console.log("after user reload", response);
              user.value = response.payload;
            } else {
              CloudFun.send("error", {
                subject: "讀取失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: error.message
            });
          });
      });
    });

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      isGuest: !CloudFun.current?.user.Id,
      user,
      loadUserData,
      photoUrl
    };
  },
  computed: {
    ...mapGetters(["user"]),
    homePath() {
      const path = this.$route.path;
      if (path.startsWith("/simple-menu")) return "/simple-menu";
      if (path.startsWith("/top-menu")) return "/top-menu";
      return "/";
    }
  },
  methods: {
    logout() {
      if (!this.user.account) {
        this.$router.push("/login");
        return;
      }
      if (this.user) {
        sessionStorage.clear();
        this.$router.push("/login");
      }
    },
    GetAccount(token: string) {
      return axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/Account`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => response.data?.payload);
    }
  },
  watch: {
    user(current) {
      this.isGuest = !current.Id;
    }
  }
});
